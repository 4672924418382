import React from "react";

const LandingReputation = () => (
  <svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_4967_35282" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="16" y="32" width="49"
          height="49">
      <path d="M64.2002 32H16.2002V81H64.2002V32Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_4967_35282)">
      <path
        d="M62.4702 57.65L62.7602 57.27C63.6902 56.06 64.2002 54.55 64.2002 53.05C64.2002 50.61 62.8202 48.3 60.5802 47C60.0802 46.71 59.5102 46.56 58.9302 46.56H42.9602L43.3802 37.98C43.4602 36.41 42.9002 34.91 41.8002 33.77C41.2702 33.2 40.6202 32.76 39.9102 32.46C39.2002 32.16 38.4402 32.01 37.6602 32.01C34.8102 32.01 32.3302 33.88 31.5802 36.57L26.0302 56.46H17.2402C16.6702 56.46 16.2002 56.93 16.2002 57.49V79.97C16.2002 80.54 16.6702 81 17.2402 81H54.7502C55.1902 81 55.6302 80.91 56.0202 80.75C58.6402 79.64 60.3302 77.12 60.3302 74.3C60.3302 73.61 60.2302 72.93 60.0402 72.29L59.9002 71.84L60.1902 71.46C61.1202 70.25 61.6302 68.74 61.6302 67.24C61.6302 66.55 61.5302 65.88 61.3402 65.23L61.2002 64.76L61.4902 64.38C62.4202 63.17 62.9302 61.66 62.9302 60.16C62.9302 59.46 62.8202 58.78 62.6302 58.14L62.4902 57.67V57.64L62.4702 57.65ZM25.7402 78.44H18.7902V59.01H25.7502V78.44H25.7402ZM60.3902 56.07L60.2902 56.18L60.0202 56.15H56.1202C55.4802 56.15 54.9802 56.67 54.9802 57.28C54.9802 57.89 55.4902 58.41 56.1202 58.41H59.9402L60.0202 58.62C60.2202 59.17 60.3102 59.66 60.3102 60.13C60.3102 61.41 59.7502 62.64 58.7602 63.49L58.6702 63.57L58.5002 63.61H55.1102C54.4702 63.61 53.9702 64.11 53.9702 64.74C53.9702 65.37 54.4802 65.87 55.1102 65.87H58.8202L58.8802 66.11C58.9702 66.47 59.0202 66.84 59.0202 67.22C59.0202 68.4 58.5102 69.57 57.6202 70.43L57.5302 70.52H54.1402C53.5002 70.52 53.0002 71.02 53.0002 71.65C53.0002 72.28 53.5102 72.78 54.1402 72.78H57.4402L57.5002 73.01C57.6502 73.49 57.7202 73.92 57.7202 74.32C57.7202 76.06 56.6802 77.65 55.0702 78.37L54.8802 78.46H27.8302L27.8702 59.54L34.0702 37.27C34.2902 36.5 34.7602 35.82 35.3902 35.33C36.0302 34.85 36.8102 34.58 37.6102 34.58C38.3102 34.58 38.9802 34.79 39.5002 35.18C40.3802 35.82 40.8502 36.8 40.8002 37.87L40.2502 49.12H59.1202L59.3502 49.26C60.7302 50.1 61.6002 51.56 61.6002 53.06C61.6002 54.17 61.1702 55.24 60.3802 56.08L60.3902 56.07Z"
        fill="#001450"/>
    </g>
    <path
      d="M44.0298 7.54004V7.56004H44.0698L51.6298 8.66004C52.0798 8.73004 52.4598 9.04004 52.5998 9.48004C52.7398 9.91004 52.6198 10.39 52.2898 10.71L46.8298 16.02L46.8098 16.04V16.07L48.0998 23.56C48.1798 24.01 47.9898 24.46 47.6198 24.73C47.2498 25 46.7598 25.03 46.3598 24.82L39.5998 21.28H39.5798H39.5598L32.7998 24.82C32.3998 25.03 31.9098 25 31.5398 24.73C31.1698 24.46 30.9898 24.01 31.0598 23.56L32.3498 16.07V16.04L32.3398 16.02L26.8798 10.71C26.5498 10.39 26.4298 9.91004 26.5698 9.48004C26.7098 9.05004 27.0898 8.73004 27.5398 8.66004L35.0998 7.56004H35.1298L35.1398 7.53004L38.5198 0.710039C38.7198 0.300039 39.1398 0.0400391 39.5998 0.0400391C40.0598 0.0400391 40.4698 0.300039 40.6798 0.710039L44.0598 7.53004L44.0298 7.54004ZM39.6198 4.04004L39.5798 3.95004L39.5398 4.04004L36.9898 9.19004C36.8098 9.54004 36.4798 9.79004 36.0898 9.84004L30.3998 10.67H30.2998L30.3698 10.76L34.4798 14.75C34.7598 15.03 34.8898 15.42 34.8298 15.81L33.8598 21.46L33.8398 21.56L33.9298 21.51L39.0298 18.84C39.3798 18.66 39.7898 18.66 40.1398 18.84L45.2398 21.51L45.3298 21.56L45.3098 21.46L44.3398 15.81C44.2698 15.42 44.3998 15.02 44.6898 14.75L48.7998 10.76L48.8698 10.69H48.7698L43.0798 9.85004C42.6898 9.79004 42.3498 9.55004 42.1798 9.20004L39.6298 4.05004L39.6198 4.04004Z"
      fill="#D5000A"/>
    <path
      d="M52.5998 9.48004C52.4598 9.04004 52.0798 8.73004 51.6298 8.66004L44.0698 7.56004H44.0298V7.54004L44.0598 7.53004L40.6798 0.710039C40.4698 0.300039 40.0598 0.0400391 39.5998 0.0400391C39.1398 0.0400391 38.7198 0.300039 38.5198 0.710039L35.1398 7.53004L35.1298 7.56004H35.0998L27.5398 8.66004C27.0898 8.73004 26.7098 9.05004 26.5698 9.48004C26.4298 9.91004 26.5498 10.39 26.8798 10.71M52.5998 9.48004L52.6498 9.46004M52.5998 9.48004C52.7398 9.91004 52.6198 10.39 52.2898 10.71L46.8298 16.02L46.8098 16.04V16.07L48.0998 23.56C48.1798 24.01 47.9898 24.46 47.6198 24.73M47.6198 24.73L47.6498 24.77M47.6198 24.73C47.2498 25 46.7598 25.03 46.3598 24.82L39.5998 21.28H39.5798H39.5598L32.7998 24.82C32.3998 25.03 31.9098 25 31.5398 24.73C31.1698 24.46 30.9898 24.01 31.0598 23.56L32.3498 16.07V16.04L32.3398 16.02L26.8798 10.71M26.8798 10.71L26.8498 10.75M34.4798 14.75L30.3698 10.76L30.2998 10.67H30.3998L36.0898 9.84004C36.4798 9.79004 36.8098 9.54004 36.9898 9.19004L39.5398 4.04004L39.5798 3.95004L39.6198 4.04004L39.6298 4.05004L42.1798 9.20004C42.3498 9.55004 42.6898 9.79004 43.0798 9.85004L48.7698 10.69H48.8698L48.7998 10.76L44.6898 14.75C44.3998 15.02 44.2698 15.42 44.3398 15.81L45.3098 21.46L45.3298 21.56L45.2398 21.51L40.1398 18.84C39.7898 18.66 39.3798 18.66 39.0298 18.84M34.4798 14.75L34.5098 14.71M34.4798 14.75C34.7598 15.03 34.8898 15.42 34.8298 15.81L33.8598 21.46L33.8398 21.56L33.9298 21.51L39.0298 18.84M39.0298 18.84L39.0098 18.8"
      stroke="#EFF6FF" strokeWidth="0.1"/>
    <path
      d="M25.8502 21.09C25.7302 20.73 25.4002 20.46 25.0102 20.4L20.0802 19.7L17.8702 15.36C17.6902 15.01 17.3402 14.8 16.9502 14.8C16.5602 14.8 16.2002 15.02 16.0302 15.36L13.8302 19.7L8.90019 20.4C8.51019 20.46 8.19019 20.72 8.06019 21.09C7.94019 21.46 8.04019 21.86 8.32019 22.13L11.8802 25.5L11.0402 30.26C10.9702 30.64 11.1302 31.0301 11.4502 31.25C11.6302 31.38 11.8402 31.44 12.0602 31.44C12.2202 31.44 12.3902 31.4 12.5402 31.32L16.9602 29.07L21.3802 31.32C21.7302 31.5 22.1502 31.47 22.4602 31.24C22.7802 31.01 22.9402 30.63 22.8702 30.25L22.0302 25.49L25.5902 22.12C25.8702 21.85 25.9802 21.44 25.8502 21.08V21.09ZM19.8902 25.32L20.4702 28.6L17.4302 27.05C17.2802 26.97 17.1202 26.94 16.9502 26.94C16.7802 26.94 16.6202 26.98 16.4702 27.05L13.4302 28.6L14.0102 25.33C14.0702 25 13.9602 24.66 13.7102 24.43L11.2702 22.12L14.6502 21.64C14.9802 21.59 15.2802 21.39 15.4302 21.09L16.9502 18.1L18.4702 21.09C18.6202 21.39 18.9102 21.59 19.2502 21.64L22.6302 22.12L20.1902 24.43C19.9402 24.66 19.8302 25 19.8902 25.33V25.32Z"
      fill="#D5000A"/>
    <path
      d="M71.6598 21.09C71.5398 20.73 71.2098 20.46 70.8198 20.4L65.8898 19.7L63.6798 15.36C63.4998 15.01 63.1498 14.8 62.7598 14.8C62.3698 14.8 62.0098 15.02 61.8398 15.36L59.6398 19.7L54.7098 20.4C54.3198 20.46 53.9998 20.72 53.8698 21.09C53.7498 21.46 53.8498 21.86 54.1298 22.13L57.6898 25.5L56.8498 30.26C56.7798 30.64 56.9398 31.0301 57.2598 31.25C57.4398 31.38 57.6498 31.44 57.8698 31.44C58.0298 31.44 58.1998 31.4 58.3498 31.32L62.7698 29.07L67.1898 31.32C67.5398 31.5 67.9598 31.47 68.2698 31.24C68.5898 31.01 68.7498 30.63 68.6798 30.25L67.8398 25.49L71.3998 22.12C71.6798 21.85 71.7898 21.44 71.6598 21.08V21.09ZM65.7098 25.32L66.2898 28.6L63.2498 27.05C63.0998 26.97 62.9398 26.94 62.7698 26.94C62.5998 26.94 62.4398 26.98 62.2898 27.05L59.2498 28.6L59.8298 25.33C59.8898 25 59.7798 24.66 59.5298 24.43L57.0898 22.12L60.4698 21.64C60.7998 21.59 61.0998 21.39 61.2498 21.09L62.7698 18.1L64.2898 21.09C64.4398 21.39 64.7298 21.59 65.0698 21.64L68.4498 22.12L66.0098 24.43C65.7598 24.66 65.6498 25 65.7098 25.33V25.32Z"
      fill="#D5000A"/>
  </svg>


);

export default LandingReputation;
